@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");
@import "./variables.scss";

* {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

.red {
  color: $red;
}

body {
  background-color: $gray;
  color: $black;
}

::selection {
  background-color: $red;
  color: $white;
}

header {
  margin: 5em 10em;

  .logo {
    height: 33px;
  }

  h1 {
    font-size: 7em;
  }
}

@media screen and (max-width: 1800px) {
  header h1 {
    font-size: 4.5em;
  }
}

@media screen and (max-width: 1200px) {
  header h1 {
    font-size: 3.5em;
  }
}

.countdown {
  margin: 0 10em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2em;
  width: max-content;
}

@media screen and (max-width: 1330px) {
  header {
    margin: 2em 5em;
  }

  .countdown {
    margin: 0 5em;
  }
}

@media screen and (max-width: 930px) {
  header {
    margin: 2em;
  }

  header h1 {
    font-size: 2.5em;
  }

  .countdown {
    margin: 0 2em;
  }
}

.countdown div {
  display: flex;
  gap: 20px;
}

.countdown div span {
  background-color: $white;
  padding: 1em;
  font-size: 3em;
  font-weight: 900;
  box-shadow: $box-shadow;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 1930px) {
  .countdown div span {
    font-size: 2em;
  }
}

@media screen and (max-width: 1200px) {
  .countdown div span {
    font-size: 1.5em;
  }
}

.countdown div span label {
  position: absolute;
  bottom: -20px;
  left: 10%;
  right: 10%;
  background-color: $white;
  box-shadow: $box-shadow;
  font-size: 0.35em;
  padding: 0.26em 0.8em;
  font-weight: 800;
  border-radius: 10px;
  text-align: center;
  border: solid 1px $border-color;
  color: $red;
}

.countdown h4 {
  display: flex;
  gap: 10px;
}
